/* DELETED
.tlui-style-panel {
    display: none;
}


.tlui-toolbar {
    display: none;
}
*/
.tlui-menu-zone {
    display: none;
}

.tlui-debug-panel {
    display: none;
}

.tlui-help-menu {
    display: none;
}

/* DELETED

.tlui-helper-buttons {
    top: 40px
}

[data-radix-popper-content-wrapper] {
    display: none;
}
*/